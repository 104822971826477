'use client';

import { useState, useEffect } from 'react';
import { Dialog, DialogContent } from './dialog';
import { Button } from './button';
import { X } from 'lucide-react';
import { Typography } from './typography';
import Image from 'next/image';
import Link from 'next/link';

interface AnnouncementPopupProps {
  id: string;
  title: string;
  content: string;
  locale: string;
  targetLocale: string;
  image: string;
  ctaText: string;
  ctaLink: string;
}

export function AnnouncementPopup({
  id,
  title,
  content,
  locale,
  targetLocale,
  image,
  ctaText,
  ctaLink
}: AnnouncementPopupProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasBeenDismissed = localStorage.getItem(`announcement-${id}`);
    if (!hasBeenDismissed && locale === targetLocale) {
      setIsOpen(true);
    }
  }, [id, locale, targetLocale]);

  const handleDismiss = () => {
    localStorage.setItem(`announcement-${id}`, 'true');
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-xl p-0 overflow-hidden">
        <div className="relative w-full aspect-video">
          <Image
            src={image}
            alt=""
            fill
            className="object-cover"
            priority
          />
          <Button
            variant="ghost"
            size="sm"
            onClick={handleDismiss}
            className="absolute top-2 right-2 h-8 w-8 p-0 bg-white/80 hover:bg-white/90"
            tabIndex={-1}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
        <div className="pb-6 pt-2 px-6">
          <Typography variant="h3" className="mb-2">
            {title}
          </Typography>
          <Typography variant="body" className="text-gray-600 mb-6">
            {content}
          </Typography>
          <Link href={ctaLink}>
            <Button onClick={handleDismiss} className="w-full">
              {ctaText}
            </Button>
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
}
