'use client';

import Link from 'next/link';
import { MenuItem } from '~/app/[locale]/(shop)/layout';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuViewport
} from '~/components/ui/navigation-menu';
import { cn } from '~/lib/utils';

export default function DesktopNavigationMenu({ menuConfig }: { menuConfig: MenuItem[] }) {
  function onNavChange() {
    setTimeout(() => {
      const triggers = document.querySelectorAll('.submenu-trigger[data-state="open"]');
      if (triggers.length === 0) return;

      const firstTrigger = triggers[0] as HTMLElement;
      const viewports = document.getElementsByClassName('submenu-viewport');

      if (viewports.length > 0) {
        const viewport = viewports[0] as HTMLElement;
        viewport.style.left = `${firstTrigger.offsetLeft}px`;
      }
    });
  }

  return (
    <NavigationMenu onValueChange={onNavChange}>
      <NavigationMenuList>
        {menuConfig.map((menuItem) => (
          <NavigationMenuItem key={menuItem.name}>
            {!menuItem.subItems ? (
              <Link href={menuItem.href} legacyBehavior passHref>
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>{menuItem.name}</NavigationMenuLink>
              </Link>
            ) : (
              <NavigationMenuTrigger className="submenu-trigger">{menuItem.name}</NavigationMenuTrigger>
            )}
            {!!menuItem.subItems && (
              <NavigationMenuContent>
                <div className='w-64 flex flex-col'>
                {menuItem.subItems.map((subItem) => (
                  <Link href={subItem.href} legacyBehavior passHref>
                    <NavigationMenuLink className={cn(navigationMenuTriggerStyle(), "w-full justify-start")}>{subItem.name}</NavigationMenuLink>
                  </Link>
                ))}
                </div>
              </NavigationMenuContent>
            )}
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}